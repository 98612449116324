# ColdSky

## The goal

Transform the wealth of data from BlueSky social media 
into accessible addressable indexed set of plain files.

## The plan

* GitHub repository for the universe of accounts   <br>
  *this includes DIDs, handle/PDS history*

* Script to download CAR files for each account   <br>
   *only locally*

* Script to process those CAR files into "materialised indices"  <br>
   *sharding data by domain-specific clusters*
